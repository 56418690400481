import React, { Component } from 'react';

class NotFound extends Component {
  render() {
    return (
      <div className='page'>
        <h1>404 Not Found</h1>
      </div>
    )
  }
}
export default NotFound;